import React from "react"
import "./style.css"

const loading = () => (
  <div class="loading">
    <div>
      <div class="c1"></div>
      <div class="c2"></div>
      <div class="c3"></div>
      <div class="c4"></div>
    </div>
    <span>loading</span>
  </div>
)

export default loading
